import axios from 'axios';
import AS from './auth-service';

const employeeService = {

  async updateEmployee(userId, payload) {
    return axios.patch(`people/employees/${userId}/update_employee`, payload, AS.getDefaultHeaders());
  },

  approveLink(userId, personId) {
    return axios.post(
      'people/employees/approve_link', { user_id: userId, person_id: personId },
      AS.getDefaultHeaders(),
    );
  },

  linkEmployee(payload) {
    const url = 'people/employees/link_employee';
    return axios.post(url, payload, AS.getDefaultHeaders());
  },

  async unlinkEmployee(userId, personId) {
    const response = await axios.post(
      'people/employees/unlink_employee',
      {
        user_id: userId,
        person_id: personId,
      },
      AS.getDefaultHeaders(),
    );
    return response.data;
  },


};

export default employeeService;
